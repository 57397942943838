<template>
    <div v-if="!$isMobile">
        <a-card style="width: 100%">
            <div class="filter-container">
                <a-form layout="inline">
                    <a-form-item label="时间维度：">
                        <a-select class="page-btm" @change="getData" v-model:value="filterForm.timeType"
                            style="width: 120px">
                            <a-select-option value="1">签单时间</a-select-option>
                            <a-select-option value="2">转精准时间</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="日期：">
                        <a-range-picker :disabled-date="disabledDate" class="page-btm" @change="getData"
                            style="width: 230px;" v-model:value="filterForm.date" :placeholder="['开始时间', '结束时间']" />
                    </a-form-item>
                    <a-form-item label="筛选部门/员工：" v-if="!hiddenFilter">
                        <a-input-group compact>
                            <a-select class="page-btm" style="width :100px" v-model:value="departmentOrEmployee"
                                placeholder="请选择" @change="getTypeData">
                                <a-select-option value="按部门">按部门</a-select-option>
                                <a-select-option value="按员工">按员工</a-select-option>
                            </a-select>
                            <a-select :filter-option="filterSouOption" show-search class="page-btm" @change="getData"
                                style="width :150px" v-model:value="departmentOrEmployeeId" placeholder="请选择">
                                <a-select-option v-for="(item, index) in departmentOrEmployeeList" :value="item.id"
                                    :key="item.id" :text="item.name">{{
                                        item.name
                                    }}</a-select-option>
                            </a-select>
                        </a-input-group>
                    </a-form-item>
                </a-form>
            </div>
        </a-card>
        <div class="card-flex-between">
            <a-card style="width: 49.5%">
                <div class="flex-between">
                    <div>
                        <span class="title">归属人聚合报表</span>
                        <span>时间维度：{{ filterForm.timeType == '1' ? '签单时间' : '转精准时间' }}</span>
                    </div>
                    <DownloadOutlined @click="download('归属人聚合报表')" />
                </div>
                <a-table :locale="{ emptyText: permissionsMsg ? permissionsMsg : '暂无数据' }" :scroll="{ x: 200 }"
                    :columns="columns" :dataSource="dataSource" :pagination="tablePagination" @change="handleTableChange">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'key'">
                            {{ record.key }}
                        </template>
                        <template v-if="column.key === 'currentSignedAmount'">
                            <p>
                                {{ '￥' + $formatCurrency(record[column.key]) }}
                            </p>
                        </template>
                        <template v-if="column.key === 'rateNum' || column.key === 'addRate'">
                            <p>
                                <span v-if="parseInt(record[column.key]) > 0" style="color: #eb2f96;">
                                    <UpCircleTwoTone two-tone-color="#eb2f96" />
                                    {{ record[column.key] }}
                                </span>
                                <span v-else-if="parseInt(record[column.key]) < 0" style="color: #41dd57;">
                                    <DownCircleTwoTone two-tone-color="#41dd57" style="color: #41dd57;" />
                                    {{ record[column.key] }}
                                </span>
                                <span v-else>
                                    {{ record[column.key] }}
                                </span>
                            </p>
                        </template>
                    </template>
                </a-table>
            </a-card>
            <a-card style="width: 49.5%">
                <div class="flex-between">
                    <div>
                        <span class="title">跟进人聚合报表</span>
                        <span>时间维度：{{ filterForm.timeType == '1' ? '签单时间' : '转精准时间' }}</span>
                    </div>
                    <DownloadOutlined @click="download('跟进人聚合报表')" />
                </div>
                <a-table :locale="{ emptyText: permissionsMsg ? permissionsMsg : '暂无数据' }" :scroll="{ x: 200 }"
                    :columns="followColumns" :dataSource="dataFollowSource" :pagination="tableFollowPagination"
                    @change="handleTableFollowChange">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'key'">
                            {{ record.key }}
                        </template>
                        <template v-if="column.key === 'currentSignedAmount'">
                            <p>
                                {{ '￥' + $formatCurrency(record[column.key]) }}
                            </p>
                        </template>
                        <template v-if="column.key === 'rateNum' || column.key === 'addRate'">
                            <p>
                                <span v-if="parseInt(record[column.key]) > 0" style="color: #eb2f96;">
                                    <UpCircleTwoTone two-tone-color="#eb2f96" />
                                    {{ record[column.key] }}
                                </span>
                                <span v-else-if="parseInt(record[column.key]) < 0" style="color: #41dd57;">
                                    <DownCircleTwoTone two-tone-color="#41dd57" style="color: #41dd57;" />
                                    {{ record[column.key] }}
                                </span>
                                <span v-else>
                                    {{ record[column.key] }}
                                </span>
                            </p>
                        </template>
                    </template>
                </a-table>
            </a-card>
        </div>
        <!-- <a-card style="width: 100%">
            <div class="flex-between">
                <div>
                    <span class="title">时间维度聚合报表</span>
                    <span>时间维度：{{ filterForm.timeType == '1' ? '签单时间' : '转商机时间' }}</span>
                </div>
                <DownloadOutlined @click="download('时间维度聚合报表')" />
            </div>
            <a-table :locale="{ emptyText: permissionsMsg ? permissionsMsg : '暂无数据' }" sticky @expand="expandCustomRow"
                :columns="yearcolumns" :dataSource="yearList" :pagination="false">
                <template #summary>
                    <a-table-summary>
                        <a-table-summary-row style="background-color: #fffff1;">
                            <a-table-summary-cell :index="0" colSpan='3'>
                                合计</a-table-summary-cell>
                            <a-table-summary-cell v-for="(item, index) in tableNoOneColumns" :key="index" :index="1">
                                <template v-if="item.dataIndex === 'currency'">
                                    {{ '￥' + $formatCurrency(combinedNums(item.key)) }}
                                </template>
                                <template v-else-if="item.key === 'chainSignedAmount'">
                                    <chainIndex :CurrentData="chainAllData('currentSignedAmount')"
                                        :LastData="combinedNums('lastSignedAmount')" title="true">
                                    </chainIndex>
                                </template>
                                <template v-else-if="item.key === 'chainRefundAmount'">
                                    <chainIndex :CurrentData="chainAllData('currentRefundAmount')"
                                        :LastData="combinedNums('lastRefundAmount')" title="true">
                                    </chainIndex>
                                </template>
                                <template v-else>
                                    {{ combinedNums(item.key) }}
                                </template>
                            </a-table-summary-cell>
                        </a-table-summary-row>
                    </a-table-summary>
                </template>
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key === 'chainSignedAmount'">
                        <chainIndex :CurrentData="record['currentSignedAmount']" :LastData="record['lastSignedAmount']"
                            title="true">
                        </chainIndex>
                    </template>
                    <template v-if="column.key === 'chainRefundAmount'">
                        <chainIndex :CurrentData="record['currentRefundAmount']" :LastData="record['lastRefundAmount']"
                            title="true">
                        </chainIndex>
                    </template>
                    <template v-if="column.dataIndex === 'currency'">
                        <p>
                            {{ '￥' + $formatCurrency(record[column.key]) }}
                        </p>
                    </template>
                </template>
            </a-table>
        </a-card> -->
    </div>
    <div class="m-data-report" v-else>
        <a-card style="width: 100%">
            <div class="filter-container">
                <a-form layout="inline">
                    <a-form-item label="时间维度：">
                        <a-select class="page-btm" @change="getData" v-model:value="filterForm.timeType"
                            style="width: 120px">
                            <a-select-option value="1">签单时间</a-select-option>
                            <a-select-option value="2">转商机时间</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="日期：">
                        <a-range-picker :popupClassName="$isMobile ? 'rangStyle' : ''" :disabled-date="disabledDate"
                            class="page-btm" @change="getData" style="width: 230px;" v-model:value="filterForm.date"
                            :placeholder="['开始时间', '结束时间']" />
                    </a-form-item>
                    <a-form-item label="筛选部门/员工：" v-if="!hiddenFilter">
                        <a-input-group compact>
                            <a-select class="page-btm" style="width :100px" v-model:value="departmentOrEmployee"
                                placeholder="请选择" @change="getTypeData">
                                <a-select-option value="按部门">按部门</a-select-option>
                                <a-select-option value="按员工">按员工</a-select-option>
                            </a-select>
                            <a-select :filter-option="filterSouOption" show-search class="page-btm" @change="getData"
                                style="width :150px" v-model:value="departmentOrEmployeeId" placeholder="请选择">
                                <a-select-option v-for="(item, index) in departmentOrEmployeeList" :value="item.id"
                                    :key="item.id" :text="item.name">{{
                                        item.name
                                    }}</a-select-option>
                            </a-select>
                        </a-input-group>
                    </a-form-item>
                </a-form>
            </div>
        </a-card>
        <a-card style="width: 100%; margin-top: 10px;">
            <div class="flex-between">
                <div>
                    <span class="title">归属人聚合报表</span>
                    <span>时间维度：{{ filterForm.timeType == '1' ? '签单时间' : '转商机时间' }}</span>
                </div>
                <DownloadOutlined @click="download('归属人聚合报表')" />
            </div>
            <a-table :locale="{ emptyText: permissionsMsg ? permissionsMsg : '暂无数据' }" :scroll="{ x: 200 }"
                :columns="columns" :dataSource="dataSource" :pagination="tablePagination" @change="handleTableChange">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'key'">
                        {{ record.key }}
                    </template>
                    <template v-if="column.key === 'currentSignedAmount'">
                        <p>
                            {{ '￥' + $formatCurrency(record[column.key]) }}
                        </p>
                    </template>
                    <template v-if="column.key === 'rateNum' || column.key === 'addRate'">
                        <p>
                            <span v-if="parseInt(record[column.key]) > 0" style="color: #eb2f96;">
                                <UpCircleTwoTone two-tone-color="#eb2f96" />
                                {{ record[column.key] }}
                            </span>
                            <span v-else-if="parseInt(record[column.key]) < 0" style="color: #41dd57;">
                                <DownCircleTwoTone two-tone-color="#41dd57" style="color: #41dd57;" />
                                {{ record[column.key] }}
                            </span>
                            <span v-else>
                                {{ record[column.key] }}
                            </span>
                        </p>
                    </template>
                </template>
            </a-table>
        </a-card>
        <a-card style="width: 100%; margin-top: 10px;">
            <div class="flex-between">
                <div>
                    <span class="title">跟进人聚合报表</span>
                    <span>时间维度：{{ filterForm.timeType == '1' ? '签单时间' : '转商机时间' }}</span>
                </div>
                <DownloadOutlined @click="download('跟进人聚合报表')" />
            </div>
            <a-table :locale="{ emptyText: permissionsMsg ? permissionsMsg : '暂无数据' }" :scroll="{ x: 200 }"
                :columns="followColumns" :dataSource="dataFollowSource" :pagination="tableFollowPagination"
                @change="handleTableFollowChange">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'key'">
                        {{ record.key }}
                    </template>
                    <template v-if="column.key === 'currentSignedAmount'">
                        <p>
                            {{ '￥' + $formatCurrency(record[column.key]) }}
                        </p>
                    </template>
                    <template v-if="column.key === 'rateNum' || column.key === 'addRate'">
                        <p>
                            <span v-if="parseInt(record[column.key]) > 0" style="color: #eb2f96;">
                                <UpCircleTwoTone two-tone-color="#eb2f96" />
                                {{ record[column.key] }}
                            </span>
                            <span v-else-if="parseInt(record[column.key]) < 0" style="color: #41dd57;">
                                <DownCircleTwoTone two-tone-color="#41dd57" style="color: #41dd57;" />
                                {{ record[column.key] }}
                            </span>
                            <span v-else>
                                {{ record[column.key] }}
                            </span>
                        </p>
                    </template>
                </template>
            </a-table>
        </a-card>
        <!-- <a-card style="width: 100%; margin-top: 10px;">
            <div class="flex-between">
                <div>
                    <span class="title">时间维度聚合报表</span>
                    <span>时间维度：{{ filterForm.timeType == '1' ? '签单时间' : '转商机时间' }}</span>
                </div>
                <DownloadOutlined @click="download('时间维度聚合报表')" />
            </div>
            <a-table :locale="{ emptyText: permissionsMsg ? permissionsMsg : '暂无数据' }" sticky @expand="expandCustomRow"
                :columns="yearcolumns" :dataSource="yearList" :pagination="false" :scroll="{ x: 2000 }">
                <template #summary>
                    <a-table-summary>
                        <a-table-summary-row style="background-color: #fffff1;">
                            <a-table-summary-cell :index="0" colSpan='3'>
                                合计</a-table-summary-cell>
                            <a-table-summary-cell v-for="(item, index) in tableNoOneColumns" :key="index" :index="1">
                                <template v-if="item.dataIndex === 'currency'">
                                    {{ '￥' + $formatCurrency(combinedNums(item.key)) }}
                                </template>
                                <template v-else-if="item.key === 'chainSignedAmount'">
                                    <chainIndex :CurrentData="chainAllData('currentSignedAmount')"
                                        :LastData="combinedNums('lastSignedAmount')" title="true">
                                    </chainIndex>
                                </template>
                                <template v-else-if="item.key === 'chainRefundAmount'">
                                    <chainIndex :CurrentData="chainAllData('currentRefundAmount')"
                                        :LastData="combinedNums('lastRefundAmount')" title="true">
                                    </chainIndex>
                                </template>
                                <template v-else>
                                    {{ combinedNums(item.key) }}
                                </template>
                            </a-table-summary-cell>
                        </a-table-summary-row>
                    </a-table-summary>
                </template>
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key === 'chainSignedAmount'">
                        <chainIndex :CurrentData="record['currentSignedAmount']" :LastData="record['lastSignedAmount']"
                            title="true">
                        </chainIndex>
                    </template>
                    <template v-if="column.key === 'chainRefundAmount'">
                        <chainIndex :CurrentData="record['currentRefundAmount']" :LastData="record['lastRefundAmount']"
                            title="true">
                        </chainIndex>
                    </template>
                    <template v-if="column.dataIndex === 'currency'">
                        <p>
                            {{ '￥' + $formatCurrency(record[column.key]) }}
                        </p>
                    </template>
                </template>
            </a-table>
        </a-card> -->
    </div>
</template>

<script>
import dayjs from "dayjs";
import TablePage from "@/components/TablePage";
import { getProfile } from "@/utils/session";
import chainIndex from "@/components/home/chainIndex";
export default {
    name: 'SaasCrmDataReportDetail',
    components: { TablePage, chainIndex },
    data() {
        return {
            filterForm: {
                timeType: '1',
                date: null,
            },
            hiddenFilter: false,
            permissionsMsg: null,
            permissions: null,
            allAttributionListData: null,
            allFollowListData: null,
            departmentOrEmployee: '按部门',
            departmentOrEmployeeId: null,
            departmentOrEmployeeList: [],
            departmentIdIs: '',
            idIn: '',
            tablePagination: {
                pageSize: 5,
                total: 0,
                current: 1,
                showTotal: total => `共 ${total} 条`,
                showSizeChanger: false,
            },
            tableFollowPagination: {
                pageSize: 5,
                total: 0,
                current: 1,
                showTotal: total => `共 ${total} 条`,
                showSizeChanger: false,
            },
            yearList: [],
            yearcolumns: [
                {
                    title: '签单时间-年',
                    dataIndex: 'year',
                    key: 'year',

                },
                {
                    title: '签单时间-月',
                    dataIndex: 'months',
                    key: 'months',

                },
                {
                    title: '签单时间-日',
                    dataIndex: 'day',
                    key: 'day',

                },
                {
                    title: '签单金额',
                    dataIndex: 'currency',
                    key: 'currentSignedAmount',
                },
                {
                    title: '环比',
                    dataIndex: 'chain',
                    width: '150px',
                    key: 'chainSignedAmount',
                },
                {
                    title: '回款金额',
                    dataIndex: 'currency',
                    key: 'currentRefundAmount',
                },
                {
                    title: '环比',
                    dataIndex: 'chain',
                    key: 'chainRefundAmount',
                },
                {
                    title: '录入客资数',
                    dataIndex: 'enterCustomerInformation',
                    key: 'enterCustomerInformation',
                },
                {
                    title: '重点客资数',
                    dataIndex: 'numberOfKeyCustomers',
                    key: 'numberOfKeyCustomers',
                },
                {
                    title: '移入公海数',
                    dataIndex: 'numberOfMovesIntoHighSeas',
                    key: 'numberOfMovesIntoHighSeas',
                },
                {
                    title: '精准客资数',
                    dataIndex: 'businessOpportunityCustomerCapital',
                    key: 'businessOpportunityCustomerCapital',
                },
                {
                    title: '签单数',
                    dataIndex: 'numberOfSignedOrders',
                    key: 'numberOfSignedOrders',
                },
                {
                    title: '回款数',
                    dataIndex: 'recoverAmount',
                    key: 'recoverAmount',
                },
            ],
            dataSource: [],
            columns: [],
            dataFollowSource: [],
            followColumns: [],
            monthsData: []
        };
    },

    mounted() {
        this.getProfile()
        this.getWeek()
        this.getDepartment()
        this.getTableData()
        this.getFollowTableData()
        // this.getTimeTableData()
    },

    computed: {
        tableNoOneColumns() {
            let tableColumns = this.yearcolumns
            let noOneColumns = tableColumns.slice(3)
            return noOneColumns
        },

    },
    methods: {
        chainAllData(key) {
            let sum = 0;
            for (let i = 0; i < this.yearList.length; i++) {
                sum += this.yearList[i][key];
            }
            return sum
        },
        combinedNums(field) {
            return this.yearList.reduce((prev, next) => {
                return prev + next[field]
            }, 0);
        },
        async getTimeTableData() {
            let { departmentIdIs, idIn } = this
            let coreDataDateOnOrAfter = this.filterForm.date[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.filterForm.date[1].format('YYYY-MM-DD 23:59:59')
            let timeType = this.filterForm.timeType
            try {
                let res = await this.$http.get('/dataReport/dataReport/aggregateReport', {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore, timeType, departmentIdIs, idIn
                });
                if (res.code == '200') {
                    const newArray = res.year.map(item => {
                        return {
                            ...item,
                            children: []
                        };
                    });
                    this.yearList = newArray.reverse()
                }
            } catch ({ message }) {
                if (message == '没有权限访问') {
                    this.permissionsMsg = '暂无权限'
                }
            }


        },
        disabledDate(current) {
            return current && current > dayjs().endOf('day');
        },
        isEndOfMonth(time) {
            const currentDate = dayjs(time);
            const isEndOfMonth = currentDate.date() === currentDate.daysInMonth();
            return isEndOfMonth
        },
        isStartOfMonth(time) {
            const currentDate = dayjs(time);
            const isStartOfMonth = currentDate.date() === 1;
            return isStartOfMonth
        },
        diffInMonths(start, end) {
            const dayjs = require('dayjs');
            const startT = dayjs(start);
            const endT = dayjs(end);
            const diffInMonths = endT.diff(startT, 'month');
            return diffInMonths
        },


        async expandCustomRow(expanded, record) {
            if (!expanded) return
            const id = record.key
            const data = this.yearList;
            let { departmentIdIs, idIn } = this
            let timeType = this.filterForm.timeType

            let coreDataDateOnOrAfter = dayjs(this.filterForm.date[0]).subtract(1, 'month').format('YYYY-MM-01 00:00:00');
            let coreDataDateOnOrBefore = this.filterForm.date[1].format('YYYY-MM-DD 23:59:59')
            let dataTime = dayjs(this.filterForm.date[0]).subtract(1, 'month').format('YYYY-MM')
            let res;
            //如果点开的是年
            if (record.year) {
                res = await this.$http.get(`/dataReport/dataReport/months`, {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore, timeType, departmentIdIs, idIn
                })
                const list = res.months
                const year = record.year;
                res.months = list.filter(item => item.months.includes(year))

            }
            //如果点开的是月
            if (record.months) {
                const month = record.months;
                const start = dayjs(this.filterForm.date[0]).subtract(1, 'day').format('YYYY-MM-DD');
                const end = dayjs(this.filterForm.date[1]).format('YYYY-MM-DD');
                const startOfMonth = dayjs(month).startOf('month').format('YYYY-MM-DD');
                const endOfMonth = dayjs(month).endOf('month').format('YYYY-MM-DD');
                if (dayjs(startOfMonth).isBefore(start)) {
                    coreDataDateOnOrAfter = dayjs(start).format('YYYY-MM-DD 00:00:00');
                } else {
                    coreDataDateOnOrAfter = dayjs(month).format('YYYY-MM-01 00:00:00');
                }
                if (dayjs(endOfMonth).isAfter(end)) {
                    coreDataDateOnOrBefore = dayjs(end).format('YYYY-MM-DD 23:59:59');
                } else {
                    coreDataDateOnOrBefore = dayjs(month).endOf('month').format('YYYY-MM-DD 23:59:59');
                }
                coreDataDateOnOrAfter = dayjs(coreDataDateOnOrAfter).subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
                res = await this.$http.get(`/dataReport/dataReport/day`, {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore, timeType, departmentIdIs, idIn
                })

                const list = res.months

                const startDate = new Date(coreDataDateOnOrAfter);
                const endDate = new Date(endOfMonth);
                const newDates = [];
                for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
                    const dateKey = dayjs(d).format('YYYY-MM-DD')
                    const existingDate = list.find(item => {
                        return dayjs(item.day).date() == d.getDate().toString()
                    });
                    if (start <= dateKey && dateKey <= end) {
                        if (existingDate) {
                            newDates.push(existingDate);
                        } else {
                            newDates.push({
                                businessOpportunityCustomerCapital: '-',
                                currentRefundAmount: '-',
                                currentSignedAmount: '-',
                                day: dateKey,
                                enterCustomerInformation: '-',
                                key: dateKey,
                                lastRefundAmount: '-',
                                lastSignedAmount: '-',
                                months: null,
                                numberOfKeyCustomers: '-',
                                numberOfMovesIntoHighSeas: '-',
                                numberOfOpportunities: '-',
                                numberOfSignedOrders: '-',
                                recoverAmount: '-',
                                year: null,
                            });
                        }
                    }
                }
                res.months = newDates
            }
            if (res.code === '200') {
                this.monthsData = res.months
                for (let i = 1; i < this.monthsData.length; i++) {
                    let diffInMonths = this.diffInMonths(this.filterForm.date[0], this.filterForm.date[1])
                    if (diffInMonths >= 3 && this.isEndOfMonth(this.filterForm.date[1]) && !this.isStartOfMonth(this.filterForm.date[0])) {
                        if (i == this.monthsData.length - 2) {
                            this.monthsData[i].lastSignedAmount = this.monthsData[i - 1].currentSignedAmount;
                            this.monthsData[i].lastRefundAmount = this.monthsData[i - 1].currentRefundAmount;
                        }
                    }
                    if (this.isStartOfMonth(this.filterForm.date[0]) && this.isEndOfMonth(this.filterForm.date[1])) {
                        this.monthsData[i].lastSignedAmount = this.monthsData[i - 1].currentSignedAmount;
                        this.monthsData[i].lastRefundAmount = this.monthsData[i - 1].currentRefundAmount;
                    }
                    if (diffInMonths !== 1 && !this.isStartOfMonth(this.filterForm.date[0]) && this.isEndOfMonth(this.filterForm.date[1])) {
                        if (i == this.monthsData.length - 1) {
                            this.monthsData[i].lastSignedAmount = this.monthsData[i - 1].currentSignedAmount;
                            this.monthsData[i].lastRefundAmount = this.monthsData[i - 1].currentRefundAmount;
                        }
                    }
                    if (this.isStartOfMonth(this.filterForm.date[0]) && !this.isEndOfMonth(this.filterForm.date[1])) {
                        if (i !== this.monthsData.length - 1) {
                            this.monthsData[i].lastSignedAmount = this.monthsData[i - 1].currentSignedAmount;
                            this.monthsData[i].lastRefundAmount = this.monthsData[i - 1].currentRefundAmount;
                        }
                    }
                    if (this.monthsData[i].day) {
                        this.monthsData[i].lastSignedAmount = this.monthsData[i - 1].currentSignedAmount;
                        this.monthsData[i].lastRefundAmount = this.monthsData[i - 1].currentRefundAmount;
                    }
                }
                if (record.months) {
                    this.monthsData.reverse().pop()
                } else {
                    this.monthsData.reverse()
                }
                const filteredYearList = this.monthsData.filter(item => item.months !== dataTime);
                const children = filteredYearList || []
                children.forEach((item) => {
                    if (!item.day) {
                        item.children = []
                    }
                })

                this.dataSourceMap(data || [], id, children)
                this.setDataSource(data)
            } else {
                this.$message.error(res.message);
            }
        },
        dataSourceMap(items, id, children) {
            items.find((item) => {
                if (item.key === id) {
                    item.children = children
                    return items
                }
                if (item.children && item.children.length > 0) {
                    this.dataSourceMap(item.children, id, children)
                }
            })
        },
        setDataSource(data) {
            this.dataSource2 = data
        },
        async getProfile() {
            try {
                let profile = await getProfile();
                this.permissions = profile.everyPermissions;
            } catch ({ message, status }) {
                if (status !== 401) {
                    this.$message.error(message);
                }
            }
        },
        download(val) {
            require.ensure([], async () => {
                const { export_json_to_excel } = require("@/utils/Export2Excel");
                let tableHeader, filterVal, dataList;
                if (val == '归属人聚合报表') {
                    if (!this.permissions('/dataReport/dataReport/attributionList')) {
                        this.$message.error("没有权限导出");
                        return;
                    }
                    tableHeader = ["排名", "归属人", "精准客资数", "环比数", "签单金额", "增长率"];
                    filterVal = ["ranking", "attributionName", "currentOpportunityNum", "rateNum", "currentSignedAmount", "addRate"];
                    dataList = this.allAttributionListData
                } else if (val == '跟进人聚合报表') {
                    if (!this.permissions('/dataReport/dataReport/followupList')) {
                        this.$message.error("没有权限导出");
                        return;
                    }
                    tableHeader = ["排名", "跟进人", "精准客资数", "环比数", "签单金额", "增长率"];
                    filterVal = ["ranking", "followUpName", "currentOpportunityNum", "rateNum", "currentSignedAmount", "addRate"];
                    dataList = this.allFollowListData
                } else if (val == '时间维度聚合报表') {
                    if (!this.permissions('/dataReport/dataReport/export')) {
                        this.$message.error("没有权限导出");
                        return;
                    }
                    if (this.departmentOrEmployee == '按部门') {
                        this.departmentIdIs = this.departmentOrEmployeeId
                        this.idIn = ''
                    } else {
                        this.idIn = this.departmentOrEmployeeId
                        this.departmentIdIs = ''
                    }
                    let { departmentIdIs, idIn } = this
                    let timeType = this.filterForm.timeType

                    let coreDataDateOnOrAfter = this.filterForm.date[0].subtract(1, 'day').format('YYYY-MM-DD 00:00:00');
                    let coreDataDateOnOrBefore = this.filterForm.date[1].format('YYYY-MM-DD 23:59:59')

                    let res = await this.$http.get('/dataReport/dataReport/export', {
                        coreDataDateOnOrAfter, coreDataDateOnOrBefore, timeType, departmentIdIs, idIn
                    });
                    if (res.code == '200') {
                        const start = dayjs(coreDataDateOnOrAfter).format('YYYY-MM-DD');
                        const end = dayjs(this.filterForm.date[1]).format('YYYY-MM-DD');
                        const list = res.months
                        const startDate = new Date(start);
                        const endDate = new Date(end);
                        const newDates = [];
                        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
                            const dateKey = dayjs(d).format('YYYY-MM-DD')
                            const existingDate = list.find(item => {
                                return dayjs(item.day).date() == d.getDate().toString()
                            });
                            if (start <= dateKey && dateKey <= end) {
                                if (existingDate) {
                                    newDates.push(existingDate);
                                } else {
                                    newDates.push({
                                        businessOpportunityCustomerCapital: '-',
                                        currentRefundAmount: '-',
                                        currentSignedAmount: '-',
                                        day: dateKey,
                                        enterCustomerInformation: '-',
                                        key: dateKey,
                                        lastRefundAmount: '-',
                                        lastSignedAmount: '-',
                                        months: null,
                                        numberOfKeyCustomers: '-',
                                        numberOfMovesIntoHighSeas: '-',
                                        numberOfOpportunities: '-',
                                        numberOfSignedOrders: '-',
                                        recoverAmount: '-',
                                        year: null,
                                    });
                                }
                            }
                        }
                        let monthsData = newDates
                        for (let i = 1; i < monthsData.length; i++) {
                            monthsData[i].lastSignedAmount = monthsData[i - 1].currentSignedAmount;
                            monthsData[i].lastRefundAmount = monthsData[i - 1].currentRefundAmount;
                            let CurrentData = monthsData[i].currentSignedAmount
                            let LastData = monthsData[i].lastSignedAmount
                            let chainSignedAmount = CurrentData !== '-' ? (((CurrentData - LastData) / LastData * 100).toFixed(2) + '%') : '-'
                            monthsData[i].chainSignedAmount = LastData > 0 ? chainSignedAmount : '-'
                            let CurrentDataRefundAmount = monthsData[i].currentRefundAmount
                            let LastDataRefundAmount = monthsData[i].lastRefundAmount
                            let chainRefundAmount = CurrentDataRefundAmount !== '-' ? (((CurrentDataRefundAmount - LastDataRefundAmount) / LastDataRefundAmount * 100).toFixed(2) + '%') : '-'
                            monthsData[i].chainRefundAmount = LastDataRefundAmount > 0 ? chainRefundAmount : '-'
                        }

                        dataList = monthsData
                        dataList.splice(0, 1)
                    }
                    let tHeader = ["签单时间-年", "签单时间-月", "签单时间-日", "签单金额", "环比", "回款金额", "环比", '录入客资数', '重点客资数', '移入公海数', '精准客资数', '签单数', '回款数',];
                    if (this.filterForm.timeType == '2') {
                        tableHeader = tHeader.map((item) => {
                            item = item.replace("签单时间", "转商机时间");
                            return item;
                        })
                    } else {
                        tableHeader = tHeader.map((item) => {
                            item = item.replace("转商机时间", "签单时间");
                            return item;
                        })
                    }
                    filterVal = ["year", "months", "day", "currentSignedAmount", "chainSignedAmount", "currentRefundAmount", "chainRefundAmount", 'enterCustomerInformation', 'numberOfKeyCustomers', 'numberOfMovesIntoHighSeas', 'businessOpportunityCustomerCapital', 'numberOfOpportunities', 'numberOfSignedOrders', 'recoverAmount'];

                }
                else { }
                let list = JSON.parse(JSON.stringify(dataList));
                let data = this.formatJson(filterVal, list);
                export_json_to_excel(tableHeader, data, val);
            });
        },
        formatJson(filterVal, Data) {
            return Data.map(v => filterVal.map(j => v[j]));
        },
        filterSouOption(input, option) {
            return option.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        getTypeData(val) {
            this.departmentOrEmployeeId = null
            if (val == '按部门') {
                this.getDepartment()
                this.getTableData()
                this.getFollowTableData()
            } else {
                this.getEmployee()
            }
        },
        handleTableChange(e) {
            this.tablePagination = {
                ...this.tablePagination,
                current: e.current
            };
            this.getTableData();
        },
        handleTableFollowChange(e) {
            this.tableFollowPagination = {
                ...this.tableFollowPagination,
                current: e.current
            };
            this.getFollowTableData();
        },
        //失去焦点获取数据
        getData() {
            if (this.filterForm.timeType == '2') {
                this.yearcolumns.map((item) => {
                    item.title = item.title.replace("签单时间", "转商机时间");
                })
            } else {
                this.yearcolumns.map((item) => {
                    item.title = item.title.replace("转商机时间", "签单时间");
                })
            }
            this.getTableData();
            this.getFollowTableData();
            // this.getTimeTableData();
        },
        //默认一周数据
        getWeek() {
            const currentDate = dayjs();
            const firstDayOfWeek = currentDate.format('YYYY-MM' + '-01 00:00:00');
            const lastDayOfWeek = currentDate.format('YYYY-MM-DD' + ' 23:59:59');
            let week = []
            week[0] = dayjs(firstDayOfWeek)
            week[1] = dayjs(lastDayOfWeek)
            this.filterForm.date = week
        },
        //获取部门数据
        async getDepartment() {
            try {
                let res = await this.$http.get('/dataReport/dataReport/list/get');
                if (res.code == '200') {
                    this.departmentOrEmployeeList = res.departmentList
                } else if (res.code == '201') {
                    this.hiddenFilter = true
                }
            } catch (error) {

            }
        },
        //获取员工数据
        async getEmployee() {
            try {
                let res = await this.$http.get('/dataReport/dataReport/employeeAll');
                if (res.code == '200') {
                    this.departmentOrEmployeeList = res.list
                }
            } catch (error) {

            }
        },
        async getTableData() {
            this.tablePagination = {
                ...this.tablePagination,
                current: this.tablePagination.current
            };
            if (this.departmentOrEmployee == '按部门') {
                this.departmentIdIs = this.departmentOrEmployeeId
                this.idIn = ''
            } else {
                this.idIn = this.departmentOrEmployeeId
                this.departmentIdIs = ''
            }
            let { departmentIdIs, idIn } = this
            let { current, pageSize } = this.tablePagination
            let startDate = this.filterForm.date[0].format('YYYY-MM-DD 00:00:00');
            let endDate = this.filterForm.date[1].format('YYYY-MM-DD 23:59:59')
            let timeType = this.filterForm.timeType
            try {
                let res = await this.$http.get('/dataReport/dataReport/attributionList', {
                    startDate, endDate, timeType, page: current, pageSize, departmentIdIs, idIn
                });
                if (res.code == '200') {
                    this.dataSource = res.data.records
                    this.allAttributionListData = res.data.allData
                    this.columns = [
                        {
                            title: '排名',
                            dataIndex: 'ranking',
                            key: 'ranking',
                            width: 50,
                            align: "center",
                            fixed: 'left',
                        },
                        {
                            title: '归属人',
                            dataIndex: 'attributionName',
                            width: 80,
                            key: 'attributionName',
                            ellipsis: true
                        },
                        {
                            title: '精准客资数',
                            dataIndex: 'currentOpportunityNum',
                            width: 100,
                            key: 'currentOpportunityNum',
                            align: "center"
                        },
                        {
                            title: '环比数',
                            dataIndex: 'rateNum',
                            width: 80,
                            key: 'rateNum',
                            align: "center"
                        },
                        {
                            title: '签单金额',
                            dataIndex: 'currentSignedAmount',
                            key: 'currentSignedAmount',
                            width: 150,
                            align: "center"
                        },
                        {
                            title: '增长率',
                            dataIndex: 'addRate',
                            width: 120,
                            key: 'addRate',
                            align: "center"
                        },
                    ]
                    this.tablePagination = { ...this.tablePagination, total: res.data.total };
                }
            } catch ({ message }) {
                if (message == '没有权限访问') {
                    this.permissionsMsg = '暂无权限'
                }

            }
        },
        async getFollowTableData() {
            this.tableFollowPagination = {
                ...this.tableFollowPagination,
                current: this.tableFollowPagination.current
            };
            if (this.departmentOrEmployee == '按部门') {
                this.departmentIdIs = this.departmentOrEmployeeId
                this.idIn = ''
            } else {
                this.idIn = this.departmentOrEmployeeId
                this.departmentIdIs = ''
            }
            let { departmentIdIs, idIn } = this
            let { current, pageSize } = this.tableFollowPagination
            let startDate = this.filterForm.date[0].format('YYYY-MM-DD 00:00:00');
            let endDate = this.filterForm.date[1].format('YYYY-MM-DD 23:59:59')
            let timeType = this.filterForm.timeType
            try {
                let res = await this.$http.get('/dataReport/dataReport/followupList', {
                    startDate, endDate, timeType, page: current, pageSize, departmentIdIs, idIn
                });
                if (res.code == '200') {
                    this.dataFollowSource = res.data.records
                    this.allFollowListData = res.data.allData
                    this.followColumns = [
                        {
                            title: '排名',
                            dataIndex: 'ranking',
                            key: 'ranking',
                            width: 50,
                            align: "center",
                            fixed: 'left',
                        },
                        {
                            title: '跟进人',
                            dataIndex: 'followUpName',
                            width: 80,
                            key: 'followUpName',
                            ellipsis: true
                        },
                        {
                            title: '精准客资数',
                            dataIndex: 'currentOpportunityNum',
                            width: 100,
                            key: 'currentOpportunityNum',
                            align: "center"

                        },
                        {
                            title: '环比数',
                            dataIndex: 'rateNum',
                            width: 100,
                            key: 'rateNum',
                            align: "center"
                        },
                        {
                            title: '签单金额',
                            dataIndex: 'currentSignedAmount',
                            key: 'currentSignedAmount',
                            width: 150,
                            align: "center",

                        },
                        {
                            title: '增长率',
                            dataIndex: 'addRate',
                            width: 100,
                            key: 'addRate',
                            align: "center"
                        },
                    ]
                    this.tableFollowPagination = { ...this.tableFollowPagination, total: res.data.total };
                }
            } catch ({ message }) {
                if (message == '没有权限访问') {
                    this.permissionsMsg = '暂无权限'
                }
            }
        },
    },
};
</script>

<style  scoped lang="scss">
.card-flex-between {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.title {
    font-weight: 600;
    margin-right: 10px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.page-btm {
    margin-bottom: 10px;
}

:deep(.ant-table-content) {
    overflow-x: auto;
    width: 100%;
}

:deep(.ant-table-content::-webkit-scrollbar) {
    width: 6px;
    height: 6px;
}

:deep(.ant-table-content::-webkit-scrollbar-thumb) {
    background-color: #ddd;
    border-radius: 3px;
}

:deep(.ant-table-content::-webkit-scrollbar) {
    width: 6px;
    height: 6px;
}

:deep(.ant-table-summary) {
    display: table-header-group;
}

.m-data-report {
    overflow: hidden;

    :deep(.ant-card-body) {
        padding: 10px;
    }

    :deep(.ant-card-head) {
        padding: 6px;
    }
}
</style>
<style lang="scss">
.rangStyle {
    .ant-picker-panels {
        display: inline-flex;
        flex-wrap: nowrap;
        direction: ltr;
        flex-direction: column;
    }
}
</style>